<template>
  <div class="top-bar">
      <el-image class="logo"  :fit="'contain'"  :src="require('../assets/img/logo1.png')" @click="handleBack" ></el-image>
      <el-menu  
      :default-active="activeIndex" 
      class="el-menu-demo" 
      mode="horizontal" 
      @select="handleSelect"
      background-color="#000000"
      text-color="#fff"
      active-text-color="#fff">

      <el-submenu index="1">
    <template slot="title">了解运动官</template>
    <el-menu-item index="1-1">使命与愿景</el-menu-item>
    <el-menu-item index="1-2">5G文化</el-menu-item>
    <el-menu-item index="1-3">五年路径</el-menu-item>
    <el-menu-item index="1-5">大事记</el-menu-item>
    <el-menu-item index="1-4">关于我们</el-menu-item>
  </el-submenu>
      <el-submenu index="2">
    <template slot="title">产品服务</template>
    <el-menu-item index="2-1">商家版APP</el-menu-item>
    <el-menu-item index="2-2">运动官小程序</el-menu-item>
  </el-submenu>
  <el-menu-item index="moment">运动瞬间</el-menu-item>
  <el-menu-item index="digitalSports">数字体育</el-menu-item>
  <el-menu-item index="mine">加入我们</el-menu-item>
</el-menu>
  </div>
</template>

<script>
export default {
 data() {
        return {
          activeIndex:'0',
          tabarList:[]
        }
      },
      methods:{
        handleSelect(key){
         switch(key){
          case'1-1':
          this.$router.push({
            path:`/understand/one${Math.floor(Math.random()*100)}`,
          })
          break;
          case'1-2':
          this.$router.push({
            path:`/understand/two${Math.floor(Math.random()*100)}`,
          })
          break;
          case'1-3':
          this.$router.push({
            path:`/understand/Three${Math.floor(Math.random()*100)}`,
          })
          break;
          case'1-4':
          this.$router.push({
            path:`/understand/four${Math.floor(Math.random()*100)}`,
          })
          break;
          case'1-5':
          this.$router.push({
            path:`/understand/Five${Math.floor(Math.random()*100)}`,
          })
          break;
          case'2-1':
          this.$router.push({
            path:`/service/one${Math.floor(Math.random()*100)}`,
          })
          break;
          case'2-2':
          this.$router.push({
            path:`/service/two${Math.floor(Math.random()*100)}`,
          })
          break;
          default:
          this.$router.push({
            path:`/${key}`,
          })
         }
          
        },
        handleBack(){
          this.activeIndex='0'
           this.$router.push({
            path:'/',
          })
        }
      },
}
</script>

<style lang="less" scoped>
.top-bar{
    width: 100%; 
    height:60px; 
   background-color: rgba(0, 0, 0, 1);
  //  opacity: 0.58;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top:0;
  z-index: 100;
   .logo{
    display: block;
    width: 100px;
    height: 25px;
    cursor: pointer;
   }
}

</style>
<style scoped>
/* 点击出来的下划线进行隐藏 */
.el-menu-item.is-active {
    border-bottom: none!important;
    color: rgba(0,0,0,0);
}
/* 整体的下划线进行隐藏 */
.el-menu.el-menu--horizontal{
  border-bottom: none;
}
/* 导航栏的间距等样式 */
.el-menu-item {
  padding: 0 62px;
  font-size: 14px;
}

/* 点击出来的下划线动效残留进行隐藏 */
.el-menu--horizontal>.el-menu-item{
    border-bottom: none;
}
/* 点击以后的背景色进行隐藏 */
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0,0,0,0);
}
.el-menu--horizontal>.el-submenu .el-submenu__title{
  border: none ;
}
.el-menu li{
  width: 120px !important;
  /* font-family:'BebaPuHui' !important; */
}
.el-menu-item{
  padding: 0;
}
.top-bar>>> .el-submenu__title{
  padding: 0 !important;
  border: none !important;
  font-size: 14px !important;
}
.top-bar>>> .el-submenu .el-submenu__icon-arrow{
  margin-left: 2px !important;
}
.top-bar>>>.el-menu-item{
  background-color: #000 !important;
  color: #fff !important;
}
</style>
