<template>
  <div>
    <headerBar/>
    <div style="margin-top:60px">
         <router-view></router-view>
    </div>
    <footerBar/>
    
  </div>
</template>

<script>
import headerBar from './header.vue'
import footerBar from './footer.vue'
export default {
    components:{
        headerBar,
        footerBar
    },
}
</script>

<style>

</style>