import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/reset/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible'
Vue.use(ElementUI);
// 弹出框的时候滚动条隐藏和出现导致页面抖动问题
ElementUI.Dialog.props.lockScroll.default = false;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
