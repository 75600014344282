<template>
  <div class="footer">
        2019-2022   运动官  -  科学运动引领者  鲁ICP备19063359号-1  广播电视节目制作经营 许可证编号：（鲁）字  第2316号  联系方式：400-857-1778   举报邮箱：support@sportsofficer.xin
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.footer{
    // position:fixed;
    border-top:1px solid #fff;
    // bottom:0;
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    // font-size: 0.01rem;
    text-align: center;
    background-color: #000000;
    color: #ccc;
}
</style>