import Vue from 'vue'
import VueRouter from 'vue-router'
import Laylout from '../components/layout.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Laylout,
    redirect: 'index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: (resolve) => require(['@/views/index'], resolve),
      },
      {
        path: '/understand/:id',
        name: 'understand',
        component: (resolve) => require(['@/views/understand'], resolve),
      },
      {
        path: '/service/:id',
        name: 'service',
        component: (resolve) => require(['@/views/service'], resolve),
      },
      {
        path: '/digitalSports',
        name: 'digitalSports',
        component: (resolve) => require(['@/views/digitalSports'], resolve),
      },
      //moment
      {
        path: '/moment', 
        name: 'moment',
        component: (resolve) => require(['@/views/moment'], resolve),
      },
      {
        path: '/mine', 
        name: 'mine',
        component: (resolve) => require(['@/views/mine'], resolve),
      },
      {
        path: '/privacy', 
        name: 'privacy',
        component: (resolve) => require(['@/views/privacy'], resolve),
      },
      {
        path: '/businessPrivacy', 
        name: 'businessPrivacy',
        component: (resolve) => require(['@/views/businessPrivacy'], resolve),
      },
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
